import React, { useContext, useEffect, useState } from "react";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useLocation } from "react-router-dom";
import { UUID } from "../../../Utility/UUID";
import "../../../assets/CFD_V1/CSS/CFD_V1.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Header from "../../Includes/Layouts/CFD_V1/Header";
import {CFD_V2Content} from '../../../Constants/CFD_V2Content';
import FormCFD_V2 from "../../Forms/FormCFD_V2";
import Featured_In from "../CFD_V1/Featured_In";
import DisrepairSec from "../CFD_V1/DisrepairSec";
import Faq_sec from "../CFD_V1/Faq_sec";
import Footer from "../../Includes/Layouts/CFD_V1/Footer";


const CFD_V2 = () => {
 
    // const queryString = useLocation().search;
    // const { saveDataIngestion } = useDataIngestion();
    // const { setExpiry, setParams } = UUID();
    // const { visitorParameters } = useContext(VisitorParamsContext);
    // setExpiry();
    // const full_url = window.location.href;
    // const query_string_arr = full_url.split("?");
    // const local_storage_uuid = localStorage.getItem('uuid');
    // let local_storage_adv_name = localStorage.getItem('adv_name');
    // var check_uuid = "";
    // if (typeof query_string_arr[1] !== "undefined") {
    //   const query = new URLSearchParams(window.location.search);
    //   check_uuid = query.get("uuid");
    // }
    // useEffect(() => {
    //   window.scrollTo(0, 0);
    //   (async () => {
    //     const visitorData = visitorParameters.visitor_parameters;
    //     if (visitorData) {
    //       const uuid = visitorData.uuid;
    //       setParams(uuid, 'QCL_V1')
    //     }
    //     await saveDataIngestion(
    //       visitorData,
    //       visitorParameters.data,
    //       "split_page_load",
    //       "QCL_V1",
    //       queryString
    //     );
    //   })();
    // }, [visitorParameters]);

    return (
        <>
       
        <AdtopiaLoadLP  pageType="lp" splitName="CFD_V2" />
        <div className="Cfd-v1">
            <Header  
                head={CFD_V2Content.header.head}
                subHead={CFD_V2Content.header.subHead}
             />
            {/* form */}
             <FormCFD_V2/>
             {/* featured In */}
             <Featured_In
                head ={CFD_V2Content.featuredcontent.head}
                featuredImage ={CFD_V2Content.featuredcontent.image}
            />
            {/* disrepair section */}
            <DisrepairSec/>
            <Faq_sec/>
            <Footer/>
            
        </div>

        </>
    );
};

export default CFD_V2;
