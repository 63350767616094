import {useState} from 'react'; 
import {Api} from '../api/Api';

export const useDataIngestion = () => {
    const [isLoading,setIsLoading] = useState(false);
    
    const saveDataIngestion = async (visitor_parameters,data,message_type,page_name,query_string,visitor_data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/data-ingestion-pipeline", {
            visitor_parameters,
            data,
            message_type,
            page_name,
            query_string,
            visitor_data
        });
        setIsLoading(false);
        return response;
    }
    return { saveDataIngestion,isLoading }
}
