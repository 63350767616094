import {React, useState,useContext} from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
import Unqulifypopup from "./Unqulifypopup";
const Slide2 = ({
  className,
  slideChange,
  backClick,
  progressText,
  progressPercent,
}) => {

  const [showpopup, setshowpopup] = useState(false);
  const handlePopShow=()=>{
    setshowpopup(true);
  };
  const handlePopClose=()=>{
    setshowpopup(false);
  };

  return (
    <>
   	<div id="slide1n" className={`col-12 top_minus ${className}`}>
			{/* <!-- progress start --> */}
		<div className="progress progrss" data-percentage={progressText}> <span className="progress-left">
			<span className="progress-bar"></span> </span> <span className="progress-right">
			<span className="progress-bar" id="progressBar"></span> </span>
					<div className="progress-value">
						<div id="progressBar_content"> <span className="perce">{progressPercent}</span>
							<br/> <span>completed</span> </div>
					</div>
		</div>
			{/* <!-- progress end --> */}
		<div className="form-group">
			<h4>Have you lived in the property for more than 1+ years?</h4>
			
			   <RadioButton 
                    id='tr100'
                    value='4'
                    name='question2'
                    className='radio-button next01n'
                    onClick={slideChange}
                    // radiolabel='Yes' 
                    tabindex='1'
                  />
			 <label className="option_but next01n" for="tr100"><span>Yes</span></label>
			
			    <RadioButton 
                    id='tr200'
                    value='5'
                    name='question2'
                    className='radio-button next01n'
                    onClick={handlePopShow}
                    // radiolabel='No' 
                    tabindex='2'
                  />
			<label className="option_but next01n" for="tr200"><span>No</span> </label>
		</div>
		<div className="col-lg-12 p-0">
		<input type="button" class="btn back01" onClick={backClick} id="back1" value="<< Back"/>
			</div>
		<div className="col-lg-12 p-0"> </div>
		<div className="clearfix"></div>
	</div>
   {/* pop up section */}
   <Unqulifypopup
                showstatus={showpopup} 
                hidepopup ={handlePopClose}
                TextContent="Sorry, we will not be able to assist you."
             />
            
          {/* close the popup section */}
    </>
  );
};
export default Slide2;
