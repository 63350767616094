import React from 'react';
const ThankyouHeader = () =>{
    return(
        <>
      <header>
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center logo">
                  <a href="#" data-id="SITE_lOGO"> <img src="/assets/CFD_V1/img/logo.png" alt=""/> </a>
               </div>
            </div>
         </div>
      </header>
      {/* <section className="banner">
         <div className="container">
            <div className="col-lg-6 offset-lg-3 col-12 text-center">
            <h1>THANK YOU!</h1>
            <p>One of our experts will get back to you shortly to discuss on your claim</p>
            </div>
         </div>
      </section> */}
        </>
    )
}

export default ThankyouHeader;