import React from "react";

const Telephone = ({ placeholder, name, className,validationMsg, validation, onBlur,phoneStyle, value, id,onKeyPress}) => {
  return (
    <>
    <input
      type="tel"
      name={name}
      className={className}
      placeholder={placeholder}
      ref={validation}
      onBlur={onBlur}
      style={phoneStyle}
      defaultValue={value}
      id={id}
      onKeyPress={onKeyPress}
      maxLength='11'
    />
    {/* {validationMsg && (
      <>
        <span className="address1_error error_msg">{validationMsg}</span>
      </>
    )} */}
    </>
  );
};
export default Telephone;
