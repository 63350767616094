import React,{useState,useEffect} from "react";
import { Modal } from 'react-bootstrap';
import Button from '../../../UI/Button';
const Unqulifypopup=({showstatus,hidepopup, TextContent, fcontent,scontent})=>{
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(showstatus);
    const handleShow = () => setShow(true);
    useEffect(() => {   
        setShow(showstatus);
    },[showstatus]);
    return(
        <div className="unqualify-modal">
        <Modal
        show={show}
        size="md"
        onHide={hidepopup}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className ="followup-modal unqualify-pop-modal dark"
        >
            <Modal.Header >

            </Modal.Header>
            <Modal.Body>
                    
                 <p className="unqualify-modal-p">{TextContent}</p>
            
            </Modal.Body>
            <Modal.Footer >
                <div className="md-footer-content">
                <button type="button" className="btn btn-secondary" onClick={hidepopup}>Back</button>
                </div>
            </Modal.Footer>
        </Modal> 
        </div>
    );
}
export default Unqulifypopup;