require('dotenv').config();

const AppDomain = process.env.REACT_APP_DOMAIN;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppDomainWithoutProtocol = window.location.hostname; //process.env.REACT_APP_DOMAIN_WITHOUT_PROTOCOL;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;

export {
    AppDomain,
    AppTitle,
    AppEnv,
    AppDomainWithoutProtocol,
    AppLpDefaultUrl,
    AppAdtopiaUPID,
    AppReferrerURL
}