import {React, useState,useContext} from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
import ModalPopupV2 from "./ModalPopupV2";
import Unqulifypopup from "./Unqulifypopup";
const Slide5 = ({
  className,
  slideChange,
  backClick,
  progressText,
  progressPercent,
}) => {
  const [showpopup, setshowpopup] = useState(false);
  const [showunqualifypopup, setshowunqualifypopup] = useState(false);
  const [fmodalcontent ,setfmodalcontent] = useState('show');
  const [smodalcontent ,setsmodalcontent] = useState('hide');
    const handlePopShow=()=>{
      setshowpopup(true);
    };
    const handlePopClose=()=>{
      setshowpopup(false);
    };

    const handleunqualifyPopShow=()=>{
      setshowunqualifypopup(true);
    };
    const handleunqualifyClose=()=>{
      setshowunqualifypopup(false);
    };


    const popupShow=()=>{
      handlePopShow();
      setTimeout(() => {
        console.log('test contens');
        setfmodalcontent('hide');
        setsmodalcontent('show');
      }, 1500);
       setTimeout(() => {
        handlePopClose();
        slideChange();
      }, 3000);
    };

    // useEffect(() => {
    //   return () => clearTimeout(timer);
    // },[]);
  return (
    <>
   	<div id="slide5n" className={`col-12 top_minus ${className}`}>
			{/* <!-- progress start --> */}
		<div className="progress progrss" data-percentage={progressText}> <span className="progress-left">
			<span className="progress-bar"></span> </span> <span className="progress-right">
			<span className="progress-bar" id="progressBar"></span> </span>
					<div className="progress-value">
						<div id="progressBar_content"> <span className="perce">{progressPercent}</span>
							<br/> <span>completed</span> </div>
					</div>
		</div>
			{/* <!-- progress end --> */}
		<div className="form-group">
			<h4>Do you LIVE in England or Wales?</h4>
			
			   <RadioButton 
                    id='tfr100'
                    value='10'
                    name='question5'
                    className='radio-button next01n'
                    onClick={popupShow}
                    // radiolabel='Yes' 
                    tabindex='1'
                  />
			 <label className="option_but next01n" for="tfr100"><span>Yes</span></label>
			
			    <RadioButton 
                    id='tfr200'
                    value='11'
                    name='question5'
                    className='radio-button next01n'
                    onClick={handleunqualifyPopShow}
                    // radiolabel='No' 
                    tabindex='2'
                  />
			<label className="option_but next01n" for="tfr200"><span>No</span> </label>
		</div>
		<div className="col-lg-12 p-0">
		<input type="button" class="btn back01" onClick={backClick} id="back4" value="<< Back"/>
			</div>
		<div className="col-lg-12 p-0"> </div>
		<div className="clearfix"></div>
	</div>

   <ModalPopupV2
              showstatus={showpopup} 
              hidepopup ={handlePopClose}
              fcontent = {fmodalcontent}
              scontent ={smodalcontent}
               TextContent=""
             />
        {/* pop up section */}
        <Unqulifypopup
        showstatus={showunqualifypopup} 
        hidepopup ={handleunqualifyClose}
        TextContent="Sorry, If you are not a resident of England and Wales, we will not be able to assist you."
      />
    
  {/* close the popup section */}
    </>
  );
};
export default Slide5;
