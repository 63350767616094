import React from "react";

const Featured_In =({head,featuredImage})=>{
   const fetauredimage = featuredImage.map((featuredImage,index)=>{
		return (
		  <div key={index} className="col-lg-6 col-md-6 col-12"><img src={featuredImage} alt=""/> </div>
		);	
   });
    return(
        <>      	
	<section className="featured">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12 col-12">
					<h3>{head}</h3>
				</div>
                    {fetauredimage}
			</div>
		</div>
	</section>
        </>
    );

}

export default Featured_In;