import {Api} from '../api/Api';

export const useUserAgent = () => {
    const getUserAgent = async (user_agent,page_type,query_string,domain_name,existingdomain,referer_site,uuid) => {
         const response = await Api.post("api/v1/user-agent", {
            user_agent,
            page_type,
            query_string,
            domain_name,
            existingdomain,
            referer_site,
            uuid

        });
        return response;
    }
    return { getUserAgent}
}