import React,{useState} from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
import Unqulifypopup from "./Unqulifypopup";
const Slide4 = ({
  className,
  slideChange,

  backClick,
  progressText,
  progressPercent
}) => {
  const [question5value, setquestion5value] = useState('');
  const [questionspan5,setQuestionspan5]  = useState('hide')
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [showpopup, setshowpopup] = useState(false);
  const handlePopShow=()=>{
    setshowpopup(true);
  };
  const handlePopClose=()=>{
    setshowpopup(false);
  };
  const subslide3=(e)=>{
    if(e.target.value == 5) {
      setChecked5(true);
      setChecked6(false);
      setquestion5value(e.target.value);
      setQuestionspan5('hide');
    } else if(e.target.value == 6) {
      setChecked6(true);
      setChecked5(false);
      setquestion5value(e.target.value);
      setQuestionspan5('hide');
    }
  }

  return (
    <>
       <div id="slide2" className={`col-12 top_minus ${className}`}>
						{/* <!-- progress start --> */}
						<div className="progress progrss" data-percentage={progressText}> <span className="progress-left">
                  <span className="progress-bar"></span> </span> <span className="progress-right">
                  <span className="progress-bar" id="progressBar"></span> </span>
							<div className="progress-value">
                            <div id="progressBar_content"> <span className="perce">{progressPercent}</span>
									<br/> <span>completed</span> </div>
							</div>
						</div>
						{/* <!-- progress end --> */}
						<div className="form-group">
                        <h4>Are the problems fixed? </h4>
                        <RadioButton 
                                id='thrv2_1'
                                value='9'
                                name='question4'
                                className='radio-button next02n'
                                onClick={slideChange}
                                // radiolabel='Option 1' 
                                tabindex='1'
                            />
                            <label className="option_but next02n" htmlFor="thrv2_1"><span>No</span> </label>
                        <RadioButton 
                                id='thrv2_2'
                                value='8'
                                name='question4'
                                className='radio-button next02n'
                                onClick={handlePopShow}
                                radiolabel='Option 2' 
                                tabindex='2'
                                />
                                <label className="option_but next02n" htmlFor="thrv2_2"><span>Yes</span></label>
                           
                                    
                           
						</div>
						<div className="col-lg-12 p-0">
            <input type="button" class="btn back01" onClick={backClick} id="back3" value="<< Back"/>
            <Button
                className ='btn back01'
                id="back1"
                value="<< Back"
                btnType="button"
                buttonOnClick ={backClick}
              />
							 </div>
						<div className="col-lg-12 p-0"> </div>
						<div className="clearfix"></div>
			 </div>
         {/* pop up section */}
         <Unqulifypopup
                showstatus={showpopup} 
                hidepopup ={handlePopClose}
                TextContent="If the problems have already been fixed, we will not be able to help you."
             />
            
          {/* close the popup section */}
    </>
  );
};
export default Slide4;
