export const CFD_V2Content    =   {
    header:{
        head:"Renting a Poorly Maintained Council or Housing Association Property?",
        subHead:'You have a Legal Right to live in a safe and repair-free home our Housing Disrepair Experts can help you claim compensation and ensure the repairs are carried out!'
     },
     
     featuredcontent:{
        head:"As featured in",
        image:[
            "/assets/CFD_V1/img/media-logo1.jpg",
            "/assets/CFD_V1/img/media-logo2.jpg",
            
        ]
    },
    bannercontent:{
        head:"So far we have successfully reclaimed over £400 million in offers for our customers",
        paragraph:"But that's only the beginning, there are still billions of pounds owed to millions of us. So what are you waiting for? Fill in your details and start your claim today"
    },
   
    middleBanner:[
        {
            image:"/assets/CFD_V1/img/img01.jpg",
            head:"ABOUT US",
            content:"<p> <strong><i> ClaimLion Law </i></strong>  was founded in 2010 by Negar Yazdani, a lawyer and former banker, to obtain financial redress for consumers for mis-sold financial products and since then, it has <strong>successfully collected compensation for tens of thousands of clients </strong> from banks and other companies on a range of financial products. </p><p>Our litigation specialists, based in London, deliver an exceptional service to clients while facilitating access to justice on a <strong>‘no win, no fee’</strong> basis. </p><p>We represent individuals against banks, insurance companies and other large companies in complex litigation. We are the leading law firm in financial mis-selling claims combining our extensive legal knowledge and financial experience to <strong>fight for compensation that is rightfully due to our clients.</strong></p>"
        },
        {
            image:"/assets/CFD_V1/img/img02.jpg",
            head:"ARE YOU ELIGIBLE TO MAKE A PPI ‘PLEVIN’ CLAIM?",
            content:"<p className='mb-1'> <strong>High Commission Levels</strong></p><p>If your provider, broker or finance company were earning more than a 50% commission on your PPI policy sale?</p><p className='mb-1'><strong>Undisclosed Commission</strong></p><p>If you were unaware of the commission being paid on your policy at the time of purchase, even if you were aware of the PPI itself?</p><p><strong>You might be eligible for compensation if any of the below applies to you, even if you have already claimed PPI or had a PPI claim dismissed:</strong></p><p><strong>If you recognise either of these scenarios, then you might have a similar case to Susan Plevin and could be eligible to make a claim.</strong></p><a href='#' className='btn eligble_btn'>Check if you are eligible</a>"
        }
    ]
 }