import React,{useState} from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
import Unqulifypopup from "./Unqulifypopup";
const Slide1 = ({
  className,
  slideChange,
  backbuttonclass,
  backClick,
  progressText,
  progressPercent,
}) => {
  const [showpopup, setshowpopup] = useState(false);
  const handlePopShow=()=>{
    setshowpopup(true);
  };
  const handlePopClose=()=>{
    setshowpopup(false);
  };
 

  return (
    <>
      <div id="slide1" className={`col-12 top_minus ${className}`}>
        {/* progress start */}
					<div className="progress progrss" data-percentage={progressText}> <span className="progress-left">
            <span className="progress-bar"></span> </span> <span className="progress-right">
            <span className="progress-bar" id="progressBar"></span> </span>
            <div className="progress-value">
              <div id="progressBar_content">
                <span className="perce">{progressPercent}</span>
                <br/> <span>completed</span>
              </div>         
						</div>
					</div>
					{/*  progress end */}

					<div className="form-group">
						<h4>Who are you renting from?</h4>							
                <RadioButton 
                  id='p_land'
                  value='1'
                  name='question1'
                  className='radio-button next01'
                  onClick={slideChange}
                  tabindex='1'
                />
              	<label className="option_but next01" for="p_land"><span>Council</span> </label>
							
                <RadioButton 
                    id='c_land'
                    value='2'
                    name='question1'
                    className='radio-button next01'
                    onClick={slideChange}
                    // radiolabel='Housing Association' 
                    tabindex='3'
                  />
						   <label className="option_but next01" for="c_land"><span>Housing Association</span> </label>
						
              <RadioButton 
                    id='h_ast'
                    value='3'
                    name='question1'
                    className='radio-button next01'
                    onClick={handlePopShow}
                    // onClick={slideChange}
                    // radiolabel='Private Landlord' 
                    tabindex='4'
                  />
                  
						<label className="option_but next01" for="h_ast"><span>Private Landlord</span> </label>
						</div>
            <Button
                                className ={`btn back01 ${backbuttonclass}`}
                                id="back2n"
                                value="<< Back"
                                btnType="button"
                                buttonOnClick ={backClick}
                            />
					</div>
          {/* pop up section */}
             <Unqulifypopup
                showstatus={showpopup} 
                hidepopup ={handlePopClose}
                TextContent="Sorry, if you are renting from Private Landlord then we will not be able to assist you."
             />
            
          {/* close the popup section */}
       
    </>
  );
};


export default Slide1;
