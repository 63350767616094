import React,{useState,useEffect } from "react";
// import "../../../Pages/Splits/THR_V6BasicContact.scss"
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { Salutation } from "../../../../Constants/Constants";
import { DobYear,DobMonth,DobDay} from '../../../../Constants/Constants';
import Button from "../../../UI/Button";
// import '../../../../assets/Unqualified/Custom.scss';
// close Post Code Functions

const BasicContactDetails = ({
  className,
  backClick,
  validation,
  validationMsg,
  setError,
  trigger,
  getValues,
  disabled,
  formSubmit,
  clearErrors,
  slideChange,
  progressText,
  progressPercent,
  headerContent,
  loaderstyle
}) => {

  const [animationclass,setAnimationclass] = useState('show');
  const [contentview,setcontentview] = useState('hide');
  const [loadercontent1,setLoadercontent1] = useState('show');
  const [loadercontent2,setLoadercontent2] = useState('hide');
  const {emailValidation}   = useEmailValidation();
  const {phoneValidation}   = usePhoneValidation();

  // setcontentview(loaderstyle);
  useEffect(() => {
    setAnimationclass('show');
    setcontentview('hide');
    setLoadercontent1('show');
    setLoadercontent2('hide');
    const timer2 = setTimeout(() => {
      setLoadercontent1('hide');
      setLoadercontent2('show');
      // window.scrollTo(0, 280);
    }, 1000);
    const timer = setTimeout(() => {
      setAnimationclass('hide');
      setcontentview('show');
      // window.scrollTo(0, 280);
    }, 3000);
    return () => clearTimeout(timer,timer2);
  },[className]);

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    const values = getValues();
    const txtFName = values['txtFName'];
    const txtLName = values['txtLName'];
    // var lstSalutationResult = await trigger("lstSalutation");
    var txtFNameResult = await trigger("txtFName");
    var txtLNameResult;
  
    if(txtFNameResult)
    {
      if((txtFName.trim() =="") || (txtFName.trim().length==0) || (txtFName.trim().length<3)){
        setError("txtFName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        window.scrollTo(0, 0)
        return false;
      }else{
        localStorage.setItem('first_name', txtFName);
        txtLNameResult = await trigger("txtLName");
      }
    }
    else{
      window.scrollTo(0, 0)
      return false;
    }
    if(txtLNameResult)
    {
      if((txtLName.trim() =="") || (txtLName.trim().length==0) || (txtLName.trim().length < 2)){
        setError("txtLName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        // window.scrollTo(0, 0)
        return false;
      } else {
        localStorage.setItem('last_name', txtLName);
        // lstDobDayResult = await trigger("lstDobDay");
      }
    }
    else{
      return false;
    }

 
    
    if ( !txtFNameResult || !txtLNameResult ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      validatecontactslide(e);
    } else {
      return false;
    }
  };
 
  const basic_onchangehandle = async(e)=>{
    trigger(e.target.name)
  }

  const namevalidate = async (e) => {
   if((e.target.value.trim() =="") || (e.target.value.trim().length==0) )
      setError(e.target.name, {
        type: "manual",
        message: "Your Name is Invalid. Please Recheck",
      });
      return false;
  }

  const validatecontactslide = async (e) => {
    e.preventDefault();
    const values = getValues();
    await trigger("txtEmail");
    await trigger("txtPhone");
    if(await  phoneValidCheck() === 1 && await emailValidCheck() === 1){
      formSubmit();
    }
  }
  
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
      if (phone !== "") {
        const getPhoneValidation      =    await phoneValidation(phone)
        if (getPhoneValidation.data.status_code === 0 || getPhoneValidation.data.status === "Fail") {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          return 0;
        } else if(getPhoneValidation.data.status_code === 2) {
          setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          return 0;
        } else{
          return 1;
        }
      }else{
        trigger('txtPhone')
        return 0;
      }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
      if (email !== "") {
        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
          const getEmailValidation      =    await emailValidation(email)
          if (getEmailValidation.data.status_code === 0) {
            setError("txtEmail", {
              type: "manual",
              message: "Email Already Exist",
            });
            return 0;
          } else{
            return 1;
          }
        }else{
          setError("txtEmail", {
            type: "manual",
            message: "Invalid Email Address",
          });
          return 0;
        }
      }else{
        trigger('txtEmail')
        return 0;
      }
  };

  return (
    <>

      <div id="slide6" className={`col-12 top_minus ${className}`} >
        			{/* <!-- progress start --> */}
		<div className="progress progrss" data-percentage={progressText}> <span className="progress-left">
			<span className="progress-bar"></span> </span> <span className="progress-right">
			<span className="progress-bar" id="progressBar"></span> </span>
					<div className="progress-value">
						<div id="progressBar_content"> <span className="perce">{progressPercent}</span>
							<br/> <span>completed</span> </div>
					</div>
		</div>
			{/* <!-- progress end --> */}

        <div className="form-group col-12">
           <h3>Please confirm your personal details so we can start your claim immediately</h3>
            <TextField
              type="text"
              className="THR_V8_inputfield form-control hero-input"
              placeholder="First Name"
              name="txtFName"
              id  ="fname"
              validation={validation({
              required: "Please Enter First Name",
              minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
              },
              pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
              },
              })}
            
              onChange ={basic_onchangehandle}
              onBlur={namevalidate}
            ></TextField>
          {validationMsg.txtFName && (<span className="error_msg "> {validationMsg.txtFName.message} </span> )}
        </div>

        <div className="form-group col-12">
            <TextField
              type="text"
              className="THR_V8_inputfield form-control hero-input"
              placeholder="Last Name"
              name="txtLName"
              id ="lname"
              validation={validation({
              required: "Please Enter Last Name",
              minLength: {
                  value: 2,
                  message: "Please Enter Valid Last Name",
              },
              pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Last Name is Invalid. Please Recheck",
              },
              })}
              onChange ={basic_onchangehandle}
              onBlur={namevalidate}
            ></TextField>
            
          {validationMsg.txtLName && (<span className="error_msg "> {validationMsg.txtLName.message} </span> )}
        </div>

        <div className=" form-group col-12">
          <div className="input-group ">
            <Email
              name="txtEmail"
              className="THR_V8_inputfield form-control"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid Email Address",
                },
              })}
              validationMsg={
                validationMsg.txtEmail && validationMsg.txtEmail.message
              }
              onChange ={basic_onchangehandle}
            ></Email>                                
            <div className="input-group-append"> 
              <span className="input-group-text">
                <img src="/assets/CFD_V1/img/privacy.png" alt=""/>
              </span> 
            </div>
          </div> 
          {validationMsg.txtEmail && (<span className="error_msg " id="email_err"> {validationMsg.txtEmail.message} </span> )}
        </div>

        <div className=" form-group col-12">
          <div className="input-group phon">
            <Telephone
              name="txtPhone"
              className="THR_V8_inputfield form-control"
              placeholder="Phone Number"
              maxlength="11"
              onBlur={phoneValidCheck}
              onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
              validation={validation({ required: true })}
              validationMsg={
                validationMsg.txtPhone && "Please Enter Valid Phone Number"
              }
              onChange ={basic_onchangehandle}
            ></Telephone>
          
            <div className="input-group-append"> 
              <span className="input-group-text">
                <img src="/assets/CFD_V1/img/privacy.png" alt=""/>
              </span> 
            </div>
          </div> 
          {validationMsg.txtPhone && (<span className="error_msg "id="phone_err"> Please Enter Valid Phone Number </span>)} 
        </div>


        <div className="col-lg-12 col-12 text-center" id="btnpscode" >
          {/* <Button
            className ='btn btn-next next06 anim_ylw'
            id="back3sub"
            value="Submit"
            btnType="button"
            name="next06"
            buttonOnClick ={basicDetailsValidation}
          />   */}
          <input
            name="next06"
            className="btn btn-next next06 anim_ylw"
            id="back3sub"
            value="Start My Claim >"
            type="button"
            disabled={disabled}
            onClick={basicDetailsValidation}
          />
        </div>
            <div className="col-lg-12 col-12 text-center p-0" >
            <input type="button" class="btn back01" onClick={backClick} id="back4" value="<< Back"/>
              {/* <Button
                className ='btn btn-secondary'
                id="back4"
                value="<< Back"
                name="back06"
                btnType="button"
                buttonOnClick ={backClick}
              /> */}
            </div>
			</div> 
    </>
  );
};
export default BasicContactDetails;
