import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';


const Header = ({head,subHead}) => {

   return(
      <>
      <FbViewPixel/>
      <header>
         <div className="logo_sec">
            <div className="container">
               <div className="col-lg-12 text-center">
                  <img src="/assets/CFD_V1/img/logo.png" alt=""/>
               </div>
            </div>
         </div>
      </header>
      <section className="banner">
         <div className="container">
            <div className="col-lg-6 offset-lg-3 col-12 text-center">
               <h1>{head}</h1>
               <h2>{subHead}</h2> 
            </div>
         </div>
      </section>
      </>
   );

};

export default  React.memo(Header);