import React from 'react';
import "../../../assets/Privacy/CSS/privacy.scss"


const PrivacyPolicy = () => {
  
  
    return (
        <>
        <div className="Privacy-page">
            <header>
                <div class="container">
                    <div class="col-lg-12 text-center">
                        <img src="/assets/CFD_V1/img/logo.png" alt=""/>
                    </div>
                </div>
            </header>

            <section class="banner">
                <div class="container">
                    <div class="col-lg-6 offset-lg-3 col-12 text-center">
                        <h1>Privacy Policy</h1>
                        <h2>The information you send us is private, and we will make sure we keep it that way</h2>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="col-lg-12 col-12">
                        <h3>Our privacy and information policies and procedures</h3>
                        <h6>We are registered with the Information Commissioner’s Office <br/>
                            Companies House registration number: 09365728 <br/>
                            Registration reference ZA121862 for FairPlane UK Limited
                        </h6>
                        <h5>Nature of work description / Nature of work</h5>
                        <p>Solicitor</p>
                        <h5>Description of processing</h5>
                        <p>The following is a broad description of the way this organisation/data controller processes personal information. To understand how your own personal information is processed you may need to refer to any personal communications you have received, check any privacy notices the organisation has provided or contact the organisation to ask about your personal circumstances.</p>
                        <h5>Reasons/purposes for processing information</h5>
                        <p>We process personal information to enable us to provide legal services including advising and acting on behalf of our clients. We also process personal information in order to maintain our own accounts and records, promote our services and to support and manage our employees.</p>
                        <h5>Type/classes of information processed</h5>
                        <p>We process information relevant to the above reasons/purposes.</p>
                        <h5>This information may include:</h5>
                        <ul>
                            <li>Personal details</li>
                            <li>Family details</li>
                            <li>Lifestyle and social circumstances</li>
                            <li>Goods and services</li>
                            <li>Financial details</li>
                            <li>Business of the person whose personal information we are processing</li>
                            <li>Education and employment details</li>
                        </ul>
                        <h5>We also process sensitive classes of information that may include:</h5>
                        <ul>
                            <li>Physical or mental health details</li>
                            <li>Racial or ethnic origin</li>
                            <li>Political opinions</li>
                            <li>Religious or other beliefs</li>
                            <li>Sexual life</li>
                            <li>Trade union membership</li>
                            <li>Offences and alleged offences</li>
                            <li>Criminal proceedings, outcomes and sentences</li>
                        </ul>
                        <h5>Whose information is processed?</h5>
                        <p>We process personal information about:</p>
                        <ul>
                            <li>Clients</li>
                            <li>Suppliers and service providers</li>
                            <li>Complainants</li>
                            <li>Enquirers</li>
                            <li>Advisers, consultants and professional experts</li>
                            <li>Employees</li>
                        </ul>
                        <h5>Who the information may be shared with</h5>
                        <p>We sometimes need to share the personal information we process with the individual themselves and also with other organisations. Where this is necessary we are required to comply with all aspects of the GDPR. What follows is a description of the types of organisations we may need to share some of the personal information we process with for one or more reasons.</p>
                        <ul>
                            <li>Where necessary or required we share information with:</li>
                            <li>Family, associates or representatives of the person whose personal data we are processing</li>
                            <li>Current, past or prospective employers</li>
                            <li>Educators and examining bodies</li>
                            <li>Healthcare professionals, social and welfare organisations</li>
                            <li>Business associates</li>
                            <li>Trade associations and professional bodies</li>
                            <li>Suppliers and service providers</li>
                            <li>Ombudsman and regulatory authorities</li>
                            <li>Employment and recruitment agencies</li>
                            <li>Complainants, enquirers</li>
                            <li>Financial organisations</li>
                            <li>Debt collection and tracing agencies</li>
                            <li>Credit reference agencies</li>
                            <li>Private investigators</li>
                            <li>Courts and tribunals</li>
                            <li>Central government</li>
                        </ul>
                        <h5>Providing financial services and advice</h5>
                        <p>Personal information is also processed in order to provide financial services and advice. For this reason the information processed may include name, contact details, family details, lifestyle and social circumstances, financial details, goods and services and sensitive classes of information that may include physical or mental health details. This information may be about clients, family and associates of clients, suppliers and enquirers. Where necessary or required this information is shared with the data subjects themselves, professional advisers and consultants, services providers, credit reference agencies.</p>
                        <h5>Transfers</h5>
                        <p>It may sometimes be necessary to transfer personal information overseas. When this is needed information may be transferred to countries or territories around the world. Any transfers made will be in full compliance with all aspects of the data protection act.</p>
                        <h5>Our procedures in practice</h5>
                        <p>FairPlane places great importance on protecting information that is supplied to us.</p>
                        <p>FairPlane treats the handling of personal information very seriously and will always act in accordance with the GDPR.</p>
                        <p>Any personal information you provide  to us will be used to enable us to assist or deal with any service that you have requested from us including that what is needed by a third party providing a product or service and working on behalf of Fairplane.   Your personal information will be kept confidential and stored securely until a time it is no longer required under the Act and will only be disclosed without  your specific approval in circumstances that are necessary by law or in compliance with a regulatory or legal process.</p>
                        <h5>Your right to the information we hold on you</h5>
                        <p>The GDPR provides you with a right to receive details of any personal data we are holding for you.  Please contact us at daniel@fairplane.co.uk or write to us at our registered office if you would like to see the information held.  If you feel any information we  hold about you is incorrect similarly please  contact  us at the same  address.</p>
                        <p>Whilst every effort  is  made to ensure this website  is safe and secure users contacting this website do so at their own discretion and at their own risk.  We cannot guarantee any emails sent by you to us or by us to you will be received or will remain secure during or following them being  sent.</p>
                        <p>We make no warranties in relation to the  accuracy of  any information which may appear on linked  websites.  Any attempt to access a linked website would be at the users  sole risk.</p>
                        <h5>Website Cookies</h5>
                        <p>FairPlane makes use of cookies to collect and store user data and to enhance the user’s experience whilst visiting the website. These are small text files that are saved on your computer that track, save,  and store information about  the users interaction and  use  of the  website.</p>
                        <p>To further optimise its website, FairPlane gathers and saves statistical data that is automatically transmitted by your internet browser, in order that we may monitor visitors to our site and in turn allowing us to understand better how our website is being used. The collection and storage of the data captured is anonymous.</p>
                        <p>You may wish to decline the use and saving of cookies from this website by adjusting the settings within your web browser’s security settings.</p>
                        <h5>Social Media Platforms</h5>
                        <p>If you communicate, engage, and take action through external  social  media platforms that this website itself participates in, you do so subject to the terms and conditions and privacy policies held at the time with each social  media platform.</p>
                        <h5>Other Business Interests</h5>
                        <p>CheckMyDeposit is a service offered to private tenants which assists them in making tenancy deposit claims against landlords who have failed to protect their rental deposits according to the law. CheckMyDeposit is a trading name of FairPlane UK Limited.</p>
                    </div>
                </div>
            </section>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>Claim for Disrepair is the trading name of FairPlane UK Ltd 2nd Floor, Westgate House Harlow, Essex CM20 1YS Tel: 0126 895 2780 Help@ClaimForDisrepair.co.uk Registered Office as above FairPlane UK Ltd Company Number 936 5728 Solicitors authorised and regulated by the Solicitors Regulation Authority, SRA number 620400</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </>
    )
}

export default PrivacyPolicy;