import React, { useState } from 'react';
import Cookiespolicy from '../Cookiespolicy';
import PrivacyPolicy from '../PrivacyPolicy';

const Footer = ()=>{

 
  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);
  const handleprivacyShow=()=>{
   setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
   setshowprivacy(false);
  };
  const handleCookieShow=()=>{
   setshowpcookies(true);
  };
  const handleCookieHide=()=>{
   setshowpcookies(false);
  };

    return (
        <>
         {/* <!-- FOOTER --> */}
      <footer>
      <div className="container">
			<div className="row">
				<div className="col-lg-12">
					<p>Claim for Disrepair is the trading name of FairPlane UK Ltd 2nd Floor, Westgate House Harlow, Essex CM20 1YS Tel: 0126 895 2780 Help@ClaimForDisrepair.co.uk Registered Office as above FairPlane UK Ltd Company Number 936 5728 Solicitors authorised and regulated by the Solicitors Regulation Authority, SRA number 620400</p>
				</div>
            <div className="col-lg-12">
               <div className="copyright">
                  <ul>
                     <li><a href="/privacy_policy" target="_blank" rel="nofollow"> Privacy Policy</a></li>
                     <li><a href="/terms_and_conditions" target="_blank" rel="nofollow">Terms & Conditions</a></li>
                  </ul>
               </div>
            </div>
			</div>
		</div>
      </footer>
      {/* <!-- FOOTER END --> */}
      {/* POP UP section  */}
      <PrivacyPolicy
        showstatus={showprivacy} 
        hidepopup ={handleprivacyClose}
      />
       <Cookiespolicy
         showstatus={showpcookies} 
         hidepopup ={handleCookieHide}
       />

      {/* close The POPUp section */}
        </>
    );
}

export default React.memo(Footer);