import React from 'react';
import "../../../assets/Terms&Conditions/CSS/terms.scss"


const TermsConditions = () => {
  
  
    return (
        <>
        <div className="Terms-page">
            <header>
                <div class="container">
                    <div class="col-lg-12 text-center">
                        <img src="/assets/CFD_V1/img/logo.png" alt=""/>
                    </div>
                </div>
            </header>

            <section class="banner">
                <div class="container">
                    <div class="col-lg-6 offset-lg-3 col-12 text-center">
                        <h1>Terms & conditions</h1>
                        <h2>Information about the service we provide</h2>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="col-lg-12 col-12">
                        <h3>Regulatory Information</h3>
                        <p>FairPlane UK Limited is registered in England under Company number 9365728.
                            Our registered office address is at 2nd Floor, Westgate House, Harlow, Essex, CM20 1YS.
                            FairPlane UK Limited is authorised and regulated by the Solicitors Regulation Authority under registration number SRA Number: 620400. Information regarding the Solicitors Regulation Authority and its regulations can be found at www.sra.org.uk
                            Our Information Commissioner Registration Number: ZA121862
                            Our VAT Registration Number is: 210 2665 54</p>
                        <h5>Complaints Policy</h5>
                        <p>At FairPlane we are committed to dealing with our clients fairly and to providing them with an excellent service. Unfortunately there may be occasions where we may not always get things right so if something has gone wrong, we would ask you tell us and we will do all we can to put things right.</p>
                        <h5>How do I make a complaint?</h5>
                        <p>You can contact us in writing (preferably by email but also by letter) or by speaking with our complaints partner: <br/>
                            Daniel Morris, <br/>
                            Partner, <br/>
                            Email: daniel@fairplane.co.uk <br/>
                            Tel: 0870 766 9997.
                        </p>
                        <p>If you wish to direct your complaint to a Director of the firm, please contact Simon Pinner on <a href="mailto:simon@fairplane.co.uk">simon@fairplane.co.uk.</a> </p>
                        <p>To help us to understand your complaint, and in order that we do not miss anything, please tell us:</p>
                        <ul>
                            <li>Your full name and contact details</li>
                            <li>Where you think we have gone wrong.</li>
                            <li>The outcome you hope to achieve as a result of your complaint</li>
                            <li>Your file reference number (if you have it)</li>
                        </ul>
                        <p>If you require any help in making your complaint we will try to help you.</p>
                        <h5>How will you deal with my complaint?</h5>
                        <p>We will record your complaint centrally.</p>
                        <p>We will write to you within three working days acknowledging your complaint, and will enclose a copy of this policy</p>
                        <p>We will investigate your complaint. This will usually involve:</p>
                        <ul>
                            <li>Reviewing your complaint</li>
                            <li>Reviewing your file(s) and other relevant documents</li>
                            <li>Speaking with the person who dealt with your matter</li>
                        </ul>
                        <p>We may also need to ask you for further information or documents. If so, we will ask you to provide the information within a specified timescale.</p>
                        <p>We will update you on the progress of your complaint at appropriate times.</p>
                        <p>We will be happy to discuss the matter with you over the telephone. We may also, if appropriate, invite you to a meeting to discuss your complaint. You do not have to attend if you do not wish to or if you are unable to.</p>
                        <p>We will write to you at the end of our investigation to tell you the outcome and what we propose to do to resolve your complaint. We will aim to do this within 21 days of the date of the initial letter we send to you.</p>
                        <h5>What if I am not satisfied with the outcome?</h5>
                        <p>We hope that you will be happy with the way we have handled your complaint, if not we would ask you to contact us again to give us a further opportunity to resolve any outstanding concerns you may have.</p>
                        <p>If you are still unhappy you can ask the Legal Ombudsman to look into your complaint. You can contact the Legal Ombudsman:</p>
                        <ul>
                            <li>By post at PO Box 6806, Wolverhampton, WV1 9WJ</li>
                            <li>By telephone: 0300 555 0333, or</li>
                            <li>By email: enquiries@legalombudsman.org.uk</li>
                        </ul>
                        <p>You must usually refer your complaint within six months of our final written response to your complaint and within six years of the act or omission about which you are complaining occurring (or within three years of you becoming aware of it). Further details are available on the website: legalombudsman.org.uk .</p>
                        <h5>Will there be cost for handling my complaint?</h5>
                        <p>We will not charge you for handling your complaint</p>
                        <p>Please note that if we have issued a bill for work done on the matter, and all or some of the bill is not paid, we may be entitled to charge interest on the amount outstanding.</p>
                        <p>The Legal Ombudsman service is free of charge.</p>
                        <h3>Terms of website use</h3>
                        <h5>Copyright</h5>
                        <p>1.1. The copyright to the content, design, and any listing in this website is owned by FairPlane UK Limited (“we” or “us”) except for any downloadable reproduced case reports, to which the terms below do not apply. You may print and download extracts from this site on the following conditions:</p>
                        <p>1.2. The documents and graphics on this site are for personal use or for use within your firm or organisation; any copies of these pages that you view, print or save to disk or to any other storage medium may only be used for your personal viewing or for viewing within your firm or organisation and any pages or extracts printed or saved to disk or stored in any way, may not be distributed to third parties or sold or otherwise circulated for profit. You may however provide extract copies to third parties as part of any professional advice you may give them, provided that the source of our material is acknowledged in all copies.</p>
                        <p>1.3. You must not reproduce or store any part of this website or include it in any public or private electronic retrieval system or service.</p>
                        <h5>2. Access to website</h5>
                        <p>2.1. While we try to ensure that this site is normally available 24 hours a day, we will not be liable for loss if, for any reason, the site is unavailable for any period of time.</p>
                        <p>2.2. Access to this site may be suspended temporarily or permanently and without notice. Where it is urgent to contact us, you can do so by email to claim@fairplane.co.uk.</p>
                        <p>2.3. Certain areas of this website may be password protected (“protected areas”). In relation to any username and/or password allocated to you by us, you will:</p>
                        <ul>
                            <li>Keep it secure and confidential and not disclose it to anyone outside your family</li>
                            <li>Immediately notify us when any person within your family is otherwise no longer authorised by you to access this website on your behalf, giving us details of all usernames and passwords of which they may have knowledge</li>

                        </ul>
                        <p>2.4. The use of any password or username allocated to you is your sole responsibility. We will not be responsible for any loss caused by authorised or unauthorised use or misuse of this website by anyone using your username or password</p>
                        <p>2.5. While we try to ensure that this site is normally available 24 hours a day, we will not be liable for loss if, for any reason, the site is unavailable for any period of time.</p>
                        <p>2.6. This website offers links to other sites – they enable you to leave this site. We do not control the linked sites and we are not responsible for the contents of any linked site or any link in a linked site, or any changes or updates to such sites. The third parties controlling those sites are responsible for ensuring that their material complies with national and relevant foreign law. We are not responsible for any error, omission or inaccuracy in their material. We are also not responsible for webcasting or any other form of transmission received from any linked site. We provide these links to you only as a convenience, and the inclusion of any link does not imply our endorsement of the site.</p>
                        <p>2.7. We will try to maintain the security and confidentiality of any communication you have with us or data you supply to us, but due to the nature of internet communications, we cannot guarantee the security or confidentiality of internet communications either in your transmission to us or our onward transmission to any insurer or third party, and will not be liable for any loss arising as a result of a breach of security or confidentiality for this reason.</p>
                        <p>2.8. Where without charge we modify your software or hardware in order to assist your communications with us (whether via this website or otherwise) or to improve the functionality of your software, we will not be responsible for any loss you suffer as a result, and it will be entirely your responsibility to check and verify the functioning of all such hardware and software following modification.</p>
                        <h5>3. The Information on this Website</h5>
                        <p>3.1. The material on this site is supplied free of charge and does not constitute financial, legal or other professional advice either in relation to the products detailed in this website, or on any other issue. You must make your own inquiries and investigations before taking (or refraining from taking) any steps based on the material on this website and you should consult the appropriate advisor if you require expert advice.</p>
                        <p>3.2. We try to ensure that the information on this site is correct, but we do not give any express or implied warranty as to its accuracy. We do not accept any liability for error or omission or loss from the use of this site, or any material contained in it, or from any action or decision taken or not taken as a result of using this site or any such material.</p>
                        <h5>4. The Interactive Services on this Website</h5>
                        <p>4.1. In relation to interactive services on this website such as online flight delay information and any similar facility, we will at all times attempt to ensure that it is accurate but this cannot be guaranteed, and we will not be liable for any loss which you may suffer as a result of you accessing that information.</p>
                        <h5>5. General Terms and Definitions</h5>
                        <p>5.1. By accessing any part of this site, you will be deemed to have accepted these terms in full.</p>
                        <p>5.2. In these Terms and Conditions the following definitions apply:</p>
                        <ul>
                            <li>“we”, “us” or “our” means FairPlane UK Limited or belonging to it.</li>
                            <li>“you” or “your” means you, your partnership, Limited Liability Partnership, Limited Company or other business organisation, and its partners, members and directors, and its employees consultants and self-employed individuals within its control.</li>
                            <li>“loss” means any damage (including, without limitation, damages for loss of business or loss of profits, reputation or goodwill) arising in contract, tort or otherwise</li>
                        </ul>
                        <p>5.3. These terms are governed by and interpreted in accordance with English Law. Any dispute will be subject to the sole jurisdiction of the Courts of England and Wales.</p>
                    </div>
                </div>
            </section>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>Claim for Disrepair is the trading name of FairPlane UK Ltd 2nd Floor, Westgate House Harlow, Essex CM20 1YS Tel: 0126 895 2780 Help@ClaimForDisrepair.co.uk Registered Office as above FairPlane UK Ltd Company Number 936 5728 Solicitors authorised and regulated by the Solicitors Regulation Authority, SRA number 620400</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </>
    )
}

export default TermsConditions;