import React from "react";

const Faq_sec = ()=>{

    return(
        <>
        {/* <!-- constitute  section --> */}
	<section className="constitute ">
		<div className="container">
			<div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-center">
                        <h3>FAQ – Common Housing Disrepair Claim Queries</h3>
                    </div>
					<div className="col-lg-6 col-md-6 col-12">
						<div className="box">
							<h4>How is it possible to make a claim against the Council or Housing Association?</h4>
							<p>As a tenant of any residential property whether it is a house or a flat you have certain rights. One of those rights is that the Council or Housing Association have a legal obligation to keep your home safe and in a reasonable state of repair. They also have a duty to make sure that any defects that you report to them are repaired in a timely manner. If your Landlord does not do that then you may have the right to claim some of your rent back in compensation.</p>
							{/* <!-- <a href="https://www.thinglink.com/scene/974988058539589634?buttonSource=viewLimits" target="_blank"><img src="dist/img/interactive_img.png" alt=""></a> --> */}
							{/* <!-- <a data-toggle="modal" data-target="#interactive_img"><img src="dist/img/interactive_img.png" alt=""></a> --> */}
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-12">
						<div className="box">
							<h4>How do I know if I can claim?</h4>
							<p>If you have any problems with your Council or Housing Association House, you have notified your Landlord of the repairs needed either to the interior or the exterior of the house and they have failed to do anything about it, there is a good chance that you may be able to make a claim not only to get the defects properly repaired but also to recover some of the rent you have paid back as compensation. You may also be able to claim for your personal belongings that have been damaged by the disrepair. If you think this applies to you, the best thing to do is get in touch with us so that we can assess your case.</p>
						</div>
					</div>
			</div>
		</div>
	</section>
	{/* <!-- constitute  section --> */}


	{/* <!-- whychoose  section --> */}
	<section className="whychoose ">
		<div className="container">
			<div className="row">
					<div className="col-lg-6 col-md-6 col-12">
						<a data-toggle="modal" data-target="#interactive_img"><img src="/assets/CFD_V1/img/bitmap.png" alt=""/></a>
					</div>
					<div className="col-lg-6 col-md-6 col-12">
                        <h3>Who are Claim for Disrepair?</h3>
                        <p>Claim for Disrepair is a Claims Management company.  We act to help clients to find legal support in placing a claim against their social landlord when necessary repairs have been left undone. We receive a fee from our partner solicitors when we introduce you to them and they accept your case.</p>
                        <p>If you so choose, you can make a complaint to your landlord about the condition of your home free directly yourself free of charge. If unsuccessful you can refer to the Housing Ombudsman Service free of charge. Information on your rights as a tenant and on alternative means of dispute resolution is available <a href="#">here.</a> </p>
				   </div>
			</div>
		</div>
	</section>
	{/* <!-- whychoose  section end--> */}
        </>
    );
}
export default React.memo(Faq_sec);