import React from "react";

const Button = ({ type, className, id, onClick, buttonText,disabled,value}) => {
  return (
    <button
        type={type}
        className={className}
        id={id}
        onClick={onClick}
        disabled={disabled}
        value={value}
    >
    {buttonText}
   </button>
  );
};
export default Button;

