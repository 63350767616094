import React from "react";

const DisrepairSec =()=>{
  return (
      <>
	<section className="disrepair">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12 col-12 text-center">
					<h3>WHAT CAN I CLAIM FOR?</h3>
					<h4>Problems you’ve reported that have
						not been fixed such as…</h4>
				</div>
					<div className="col-lg-6 col-md-6 col-12">
						
						<ul>
							<li>ANY disrepair that has caused ill health</li>
							<li>Damp or mould that’s NOT caused by condensation</li>
							<li>Damp or rotten flooring</li>
							<li>Broken or faulty boilers or central heating systems</li>
							<li>Leaking roof or leaking gutters</li>
							<li>Damaged or defective electrics, lights, sockets etc</li>
							<li>Broken or rotten windows and doors</li>
					   </ul>

					   <p><a href="#" className="strtbtn"> Get Started </a></p>
						
					</div>
					<div className="col-lg-6 col-md-6 col-12">
						<img src="/assets/CFD_V1/img/group-9.png" alt=""/>
					</div>			
			</div>
		</div>
	</section>
      </>
  );
}
export default DisrepairSec;