import React, { useEffect, useState } from "react";
import { BrowserRouter, Route,Switch } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import queryString from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
// import Matomo from "./Utility/Matomo";
import "./App.scss";
import CFD_V1 from "./Components/Pages/Splits/CFD_V1";
import CFD_V2 from "./Components/Pages/Splits/CFD_V2";
import Thankyou from "./Components/Pages/Thankyou";
import PrivacyPolicy from "./Components/Includes/Layouts/PrivacyPage";
import TermsConditions from "./Components/Includes/Layouts/TermsPage";

// import { useUserAgent } from "./Hooks/useUserAgent";
// import { useLocation } from "react-router-dom";
// import { UUID } from "./Utility/UUID";
// import NotFound from "./Components/Pages/NotFound";
// import Adv_V1Contact from "./Components/Pages/Adv_V1Contact";


const App = () => {
  const [visitorParameters, setVisitorParameters] = useState({});
  useEffect(()=>{
     document.title = EnvConstants.AppTitle;
  },[]);

  return (
    <>
      {/* {EnvConstants.AppEnv==='prod'
        ? <Matomo/>
        : ''
      } */}
       <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <BrowserRouter>
            <Route exact path="/" component={HomePage}/>
            <Route exact path={["/CFD_V1"]} component={CFD_V1} />
            <Route exact path={["/CFD_V2"]} component={CFD_V2} />
            <Route exact path={"/thankyou"} component={Thankyou} /> 
            <Route exact path={"/privacy_policy"} component={PrivacyPolicy} /> 
            <Route exact path={"/terms_and_conditions"} component={TermsConditions} /> 
            <Route exact path={["/fbpixel"]} component={FbPixel} />
           
          </BrowserRouter>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default App;
