import React, { useContext, useEffect } from 'react';
import { useAdtopiaLoadApi } from '../Hooks/useAdtopiaLoadApi';
import { AppDomainWithoutProtocol, AppDomain, AppLpDefaultUrl, AppAdtopiaUPID, AppReferrerURL } from "../Constants/EnvConstants";
import queryString from "./QueryString";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../Contexts/VisitorParams";

const AdtopiaLoadRoot = ({ pagetype, renderSplitName,splitName }) => {

    const history = useHistory();
    const query = new URLSearchParams(window.location.search);
   
    const encryptVal = query.get('value');
    const { getUUID, setExpiry } = UUID();
    const { adtopiaLoadResponse,adtopiaLoadingErr } = useAdtopiaLoadApi();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const field = 'uuid';

    useEffect(async () => {
      
        let uuidType = 'new';
        if (encryptVal) {
            uuidType = 'new';
        } else {
            uuidType = 'check';
        }
       
        const getUUIDParam = await getUUID(
            splitName,
            uuidType
        );
       
      
        const uuid = getUUIDParam.data.response.uuid;
        const ip_address = getUUIDParam.data.response.ip_address;
        localStorage.setItem('ip_address', ip_address);

        const getdtopiaApi = await adtopiaLoadResponse(
            AppDomain + '?' + queryString,
            AppDomainWithoutProtocol,
            window.navigator.userAgent,
            ip_address,
            uuid,
            AppAdtopiaUPID,
            AppReferrerURL
        );

        if(adtopiaLoadingErr){ //when adtopia api is failed
            console.log("adtopia api failed");
            history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid); 
        }
        if(typeof getdtopiaApi !=="undefined") {
            const getdtopiaApiResponse = getdtopiaApi.data;

            if (getdtopiaApiResponse.response === 200) { // success response from adtopia loader api
                //renderSplitName(getdtopiaApiResponse.split_full_name+'?'+getdtopiaApiResponse.url_param+'&uuid='+uuid);
                //User Agent API
                if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) { // if both domains are same
                        console.log("2222222==" + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param);
                        if(getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1){
                            history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param);
                        }else{
                            history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param + '&uuid='+uuid);
                        }
                } else {
                        window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid;
                }
            } else if (getdtopiaApiResponse.response === 404) {
                console.log("333333333=" + AppLpDefaultUrl + '?uuid=' + uuid)
                if(getdtopiaApiResponse.url_param !=""){
                    if(getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1){
                        history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
                    }else{
                        history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid='+uuid);
                    }
                }else{
                    history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);  
                }
            } else {
                console.log("55555=" + AppLpDefaultUrl + '?uuid=' + uuid)
                if(getdtopiaApiResponse.url_param !=""){
                    if(getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1){
                        history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
                    }else{
                        history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid='+uuid);
                    }
                }else{
                    history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);  
                }
            }
        }else{
            history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
        }
        
        //console.log(getdtopiaApi);
    }, [])


    // if(pagetype === 'home') { // root domain
    // }

    // console.log("adtopia load");
    return (
        <></>
    )
}

export default AdtopiaLoadRoot;