import React,{useState,useEffect} from "react";
import { Modal } from 'react-bootstrap';
import Button from '../../../UI/Button';
const ModalPopupV2=({showstatus,hidepopup, TextContent, fcontent,scontent})=>{
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(showstatus);
    const handleShow = () => setShow(true);
    useEffect(() => {   
        setShow(showstatus);
    },[showstatus]);
    return(
        <div className="unqualify-modal">
        <Modal
        show={show}
        size="md"
        onHide={hidepopup}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className ="followup-modal analysmodal-pop dark"
        >
            {/* <Modal.Header >

            </Modal.Header> */}
            <Modal.Body>
                    <div id="slide6" className={`col-12 slide-pad text-center slide6 ${fcontent} `}>
                        <div className="form-group text-center" id="analize1">
                            <h2>Analysing Your Results</h2>
                            <img src="/assets/CFD_V1/img/review.gif" alt=""/>
                        </div>
                </div>
                <div id="slide6nxt" className={`col-12 slide-pad text-center slide6nxt animated fadeInUp ${scontent} `} >
                    <div className="form-group text-center" id="analize1">
                        <h2>Good News! </h2>
                        <p className="p_text">We are able to assist you further. Please give us some back information. <br/>(This will only take 1 minute)</p>
                        <img src="/assets/CFD_V1/img/review.gif" alt=""/>
                    </div>
                </div>
                 {/* <p className="unqualify-modal-p">{TextContent}</p> */}
            
            </Modal.Body>
            {/* <Modal.Footer >
                <div className="md-footer-content">
                <button type="button" className="btn btn-secondary" onClick={hidepopup}>Back</button>
                </div>
            </Modal.Footer> */}
        </Modal> 
        </div>
    );
}
export default ModalPopupV2;