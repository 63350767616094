import React from "react";
const Email = ({ placeholder, name, className, validation,validationMsg, onBlur,emailStyle}) => {
  return (
    <>
      <input
        type="email"
        name={name}
        className={className}
        placeholder={placeholder}
        ref={validation}
        onBlur={onBlur}
        style={emailStyle}
      />
      {/* {validationMsg && (
      <>
        <span className="address1_error error_msg">{validationMsg}</span>
      </>
    )} */}
    </>
  );
};
export default Email;
