import React,{useEffect, useState, useRef, useReducer, useContext} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import {useDataIngestion} from "../../Hooks/useDataIngestion";


import Slide1 from "../Includes/Layouts/CFD_V1/Slide1";
import Slide2 from "../Includes/Layouts/CFD_V1/Slide2";
import Slide3 from "../Includes/Layouts/CFD_V1/Slide3";
import Slide4 from "../Includes/Layouts/CFD_V1/Slide4";
import Slide5 from "../Includes/Layouts/CFD_V1/Slide5";
import BasicContactDetails from "../Includes/Layouts/CFD_V1/BasicContactDetails";


const initialState = {
    slide1: "show",
    slide2: "hide",
    slide3: "hide",
    slide4: "hide",
    slide5: "hide",
    slide6: "hide",
}
// reducer
const THR_V1Reducer = (state, action) => {
    switch (action.type) {
      case 'slide1': {
        return {
          ...state,
          slide1: action.payload.slide1,
          slide2: action.payload.slide2,
        }
      }
      case 'backslide1': {
        return {
          ...state,
          slide1: action.payload.slide1,
          slide2: action.payload.slide2,
          progressclass: action.payload.progressclass,
        }
      }
      case 'slide2': {
        return {
          ...state,
          slide2: action.payload.slide2,
          slide3: action.payload.slide3,
        }
      }
      case 'backslide2': {
        return {
          ...state,
          slide2: action.payload.slide2,
          slide3: action.payload.slide3
        }
      }
      case 'slide3': {
        return {
          ...state,
          slide3: action.payload.slide3,
          slide4: action.payload.slide4
        }
      }
      case 'backslide3': {
        return {
          ...state,
          slide3: action.payload.slide3,
          slide4: action.payload.slide4
        }
      }
      case 'slide4': {
        return {
          ...state,
          slide4: action.payload.slide4,
          slide5: action.payload.slide5
        }
      }
      case 'backslide4': {
        return {
          ...state,
          slide4: action.payload.slide4,
          slide5: action.payload.slide5
        }
      }
      case 'slide5': {
        return {
          ...state,
          slide5: action.payload.slide5,
          slide6: action.payload.slide6
        }
      }
      case 'backslide5': {
        return {
          ...state,
          slide5: action.payload.slide5,
          slide6: action.payload.slide6
        }
      }
      case 'slide6': {
        return {
          ...state,
          slide6: action.payload.slide6,
          slide7: action.payload.slide7
        }
      }
      case 'backslide6': {
        return {
          ...state,
          slide6: action.payload.slide6,
          slide7: action.payload.slide7
        }
      }
      case 'slide7': {
        return {
          ...state,
          slide7: action.payload.slide7,
          slide8: action.payload.slide8
        }
      }
      case 'backslide7': {
        return {
          ...state,
          slide7: action.payload.slide7,
          slide8: action.payload.slide8
        }
      }
      case 'slide8': {

        return {
          ...state,
          slide8: action.payload.slide8,
          slide9: action.payload.slide9
        }
      }
      case 'backslide8': {
        return {
          ...state,
          slide8: action.payload.slide8,
          slide9: action.payload.slide9
        }
      }
      case 'slide9': {
        
        return {
          ...state,
          slide9: action.payload.slide9,
          slide10: action.payload.slide10
        }
      }
      case 'backslide9': {
        return {
          ...state,
          slide9: action.payload.slide9,
          slide10: action.payload.slide10
        }
      }
  
  
      default:
        return state;
    }
  }
  
const FormCFD_V2 = () =>{

   
    const [lenderSelectedList, setLenderSelectedList] = useState([]);
    const [signaturePad, setSignaturePad] = useState();
    const [savedSignature, setSavedSignature] = useState("");
    const [othersModalShow, setOthersModalShow] = useState(false);
    const [isSignValid, setIsSignValid] = useState(false);
    const signPadRef = useRef();
    const canvasRef = useRef();
    const scrollRef = useRef([]);
    const splitForm = useRef(null);
    const [emailCheck, setEmailCheck] = useState('hide');
    const [isAgreedChecked, setIsAgreedChecked] = useState(false);
    const [frmbtnDisabled, setFrmBtnDisabled] = useState(false);
    const [isLenders, setIsLenders] = useState(false);
    const [bankValidationMsg, setBankValidationMsg] = useState();
    const scrollfrmv2div = useRef(null);
    var flFormSubmit = false;
    const [btnDisabled, setBtnDisabled] = useState(false);
    const field = "pid";
    const {
        visitorParameters
    } = useContext(VisitorParamsContext);
    const {
        queryString
    } = useContext(QueryStringContext);
    const {
        saveDataIngestion,
        isLoading
    } = useDataIngestion();
    const history = useHistory();


    // use Form
    const {
        register,
        errors,
        trigger,
        setError,
        clearErrors,
        getValues,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

 
    // const initialState = {
    //     isAgreed: false,
    //     isLenderCheck: false,
    //     isCanvasEmpty: false,

    // };
    const slide1=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'slide1',
          payload: {
            slide2: "show",
            slide1: "hide",
          }
        });
      }
      
      const backslide1=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'backslide1',
          payload: {
            slide2: "hide",
            slide1: "show",
          }
        });
      }
      
      const slide2=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'slide2',
          payload: {
            slide3: "show",
            slide2: "hide",
          }
        });
      }
      const backslide2=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'backslide2',
          payload: {
            slide3: "hide",
            slide2: "show",
          }
        });
      }
      
      const slide3=()=>{
        window.scrollTo({
        top:scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'slide3',
          payload: {
            slide4: "show",
            slide3: "hide",
          }
        });
      }
      const backslide3=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'backslide3',
          payload: {
            slide4: "hide",
            slide3: "show",
          }
        });
      }
      
      const slide4=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'slide4',
          payload: {
            slide5: "show",
            slide4: "hide",
          }
        });
      }
      const backslide4=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'backslide4',
          payload: {
            slide5: "hide",
            slide4: "show",
          }
        });
      }
      const slide5=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'slide5',
          payload: {
            slide6: "show",
            slide5: "hide",
          }
        });
      }
      const backslide5=()=>{
        window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        return dispatch({
          type:'backslide5',
          payload: {
            slide6: "hide",
            slide5: "show",
          }
        });
      }


      const [state, dispatch] = useReducer(THR_V1Reducer, initialState);

    const scrollToElement = (element) => {

        scrollRef.current[element]?.scrollIntoView({
            behavior: "smooth",
            block: 'center',
            inline: 'center'
        });
    };


    const formSubmit = async () => {
        let errorFlag = 0;
        // setLoaderClass("load show");
        setBtnDisabled(true);
        const form = splitForm.current;
        const values = getValues();
        const formData = values;
      
        
        formData.question1  = form['question1'].value;
        formData.question2  = form['question2'].value;
        formData.question3  = form['question3'].value;
        formData.question4  = form['question4'].value;
        formData.question5  = form['question5'].value;
        formData.page_name = 'CFD_V2';
      console.log(formData);

      if(errorFlag  === 0 && flFormSubmit === false){
        flFormSubmit = true;
        
        if (visitorParameters.data !== null ||visitorParameters.data !== undefined) {
        //   setLoaderClass("load show");
          setBtnDisabled(true);

          const formSUbmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            'split_form_submit',
            'CFD_V2',
            queryString,
            visitorParameters.data
          )
          if (formSUbmitResult.data.status === "Success") {
            if (queryString != null) {
              if (queryString.indexOf("&" + field + "=") !== -1) {
                history.push("/fbpixel"); // fb fixel firing
              } else {
                history.push(
                  "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
                );
              }
            } else {
              history.push(
                "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          }
        } else {
        //   setLoaderClass("load hide");
          setBtnDisabled(false);
        }
      
      }
      };
      

    const basic_onchangehandle = async (e) => {
        document.getElementById("btn_submit_claim").disabled = false;
        trigger(e.target.name);
    };


    return(
        <>
      {/* <!--  ========= FormSection  Area Start  ========= --> */}
      <section className="form-part">
         <div className="container">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12" ref={scrollfrmv2div}>
              <form   ref={splitForm}  name="split_form" id="user_form" method="POST" autoComplete="off">
                {/* <!-- slide 1 --> */}
              
                    <Slide1
                        className={` ${state.slide1}`}
                        slideChange ={slide1}
                        backbuttonclass = 'hide'
                        progressText  = '10'
                        progressPercent  = '10%'
                     />
                     <Slide2
                      className={` ${state.slide2}`}
                      slideChange ={slide2}
                      backClick ={backslide1}
                      progressText  = '24'
                      progressPercent  = '24%'
                     />
                    <Slide3
                      className={` ${state.slide3}`}
                      slideChange ={slide3}
                      backClick ={backslide2}
                      progressText  = '36'
                      progressPercent  = '36%'
                     />
                {/* Slide 4 */}
                   <Slide4
                    className={` ${state.slide4}`}
                    slideChange ={slide4}
                    backClick ={backslide3}
                    progressText  = '72'
                    progressPercent  = '72%'
                    />
                    <Slide5
                    className={` ${state.slide5}`}
                    slideChange ={slide5}
                    backClick ={backslide4}
                    progressText  = '84'
                    progressPercent  = '84%'
                    />
                  <BasicContactDetails
                        className={` ${state.slide6}`}
                        formSubmit ={formSubmit}
                        backClick ={backslide5}
                        setError={setError}
                        validation={register}
                        clearErrors={clearErrors}
                        validationMsg={errors}
                        trigger={trigger}
                        getValues={getValues}
                        progressText="95"
                        progressPercent="95%"
                        disabled ={btnDisabled}
                        />
              </form>
            </div>
         </div>
      </section>
   
      
        </>
    );
}

 export default FormCFD_V2;