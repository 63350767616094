import React, {useRef, useState, useContext,useReducer,useEffect } from 'react';
import "../../assets/Thankyou/css/thankyou.scss"
import Footer from '../Includes/Layouts/CFD_V1/Footer';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import Header from '../Includes/Layouts/CFD_V1/Header';
import ThankyouHeader from '../Includes/Layouts/ThankyouHeader';
import { userInfo } from '../../Hooks/userInfo';

const Thankyou = () => {
  
   const [userFirstName, setUserFirstName] = useState("");
   const [userLastName, setUserLastName] = useState("");
   const { visitorParameters } = useContext(VisitorParamsContext);
   const { getUserInfo } = userInfo();
   const queryParams = new URLSearchParams(window.location.search);
   const queryuuid = queryParams.get('uuid');
   const first_name = localStorage.getItem('first_name');
   const last_name = localStorage.getItem('last_name');
   let uuid = "";
   
   useEffect(() => {
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: 'smooth'
      });
      CheckUser();
    }, []);


   const CheckUser = async () => {
      if(first_name != null && last_name != null ) {
         setUserFirstName(first_name);
         setUserLastName(last_name);
      } else {
         if(visitorParameters.visitor_parameters != undefined)
         {
            uuid = visitorParameters.visitor_parameters.uuid;
         } else {
            uuid = queryuuid;
         }
         const userDetails = await getUserInfo(uuid);
         setUserFirstName(userDetails.data.first_name);
         setUserLastName(userDetails.data.last_name);
      }
   }

   return (
      <>
         <div className="Thankyou-page">
            <ThankyouHeader/>
            <section class="namesec">
               <div class="container">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                     <p><b>Dear {userFirstName} {userLastName},</b></p>
                     <p>Thank you for making an enquiry with us at Claim for Disrepair. You should receive a call shortly from one of our Claims Handlers from 01792 737773 to discuss the next steps.</p>
                     <p>At Claim for Disrepair, we operate on a No Win, No Fee basis and help tenants with outstanding repairs that have been reported to their Local Authority or Housing Association landlord but are still waiting to be fixed.</p>
                     <p>If your claim qualifies, a solicitor will make a claim on your behalf to your landlord to complete the repairs on your home and compensate you for the inconvenience. Any out-of- pocket expenses which are directly attributable to the failure of the landlord to fulfil their Section 11 Landlord and Tenant Act obligations will also be compensated for.</p>
                  </div>
               </div>
            </section>

            <section class="dividedsec">
               <div class="container">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="row">
                              <div class="col-lg-6 col-md-6 colsm-12 col-12 rightborder">
                                 <h1>Does Your Claim Qualify?</h1>
                                 <p>To qualify for our help, there must be a state of “disrepair”. Common examples of this include:</p>
                                 <ul>
                                    <li>Something structural (external or internal including pipework and services such as gas, electric or water) broken that your landlord has not fixed.</li>
                                    
                                    <li>High levels of damp, not caused by everyday condensation but by a leak, faulty guttering, or similar ingress of water.</li>
                                    
                                    <li>An un-dealt with rodent problem that has caused severe damage to your home.</li>
                                 </ul>
                                 <p>If you can say yes to any of the above, read on…</p>
                                 <ul>
                                    <li>Is your landlord a housing association or local authority?</li>
                                    <li>Have you reported the need for repairs to your landlord more than once?</li>
                                    <li>Has it been more than 3 months since you reported the need for repairs to your landlord without them fixing the problem?</li>
                                    <li>Are you in an on-going tenancy and not in the process of moving out or being evicted?</li>
                                 </ul>
                                 <p>If the answer is also yes to all of these, then there is a good chance we can help you to get your home fixed and win some compensation.</p>
                              </div>
                              
                              <div class="col-lg-6 col-md-6 colsm-12 col-12 helptext">
                                 <h1>Help Us To Help You</h1>
                                 <p>To help you to get the support you need, we will need to document a case and present it to one of our team of specialist solicitors. The more detail we have, the better your circumstances can be understood by both the solicitor and the local authority.</p>
                                 <p>Increase the chances of your claim by;</p>
                                 <ul>
                                    <li>Sending as many photos as you can – put your name in the subject header. Photos should be clear, well-lit and include images of the cause (leaky pipe etc) as well as the symptom (damp).</li>
                                    <li>Sending us pictures of the whole room so we can see the damage as a whole – this is very important.</li>
                                    <li>Sending us any medical notes, letters from the GP, independent surveyor, builder or any other professional.</li>
                                 </ul>
                                 <p>If you do have any questions, then feel free to contact us via Facebook, Email, Text or Call us on 01792 737773.</p>
                                 <p>We look forward to getting your home back in order.</p>
                                 <p>Case Management Team</p>
                              </div>
                     
                        </div>
                     </div>
               </div>
            </section>
            {/* <section>
               <div class="container">
                  <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 text-center">
                     <img src="/assets/CFD_V1/img/tick.png" alt="" style={{width:'25%'}}/>
                     <h1>THANK YOU!</h1>
                     <p>One of our experts will get back to you shortly to discuss on your claim</p>
                  </div>
               </div>
            </section>     */}
            <Footer/>        
         </div>
      </>

   )
}

export default Thankyou;
